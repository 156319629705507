body {
  overflow-x: hidden;

  &.noscroll {
    overflow: hidden;
  }
}

.pac-container {
  z-index: 9999;
}

#atcb-reference {
  display: none !important;
}
