@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/bootstrap-reboot";
@import "bootstrap/scss/bootstrap-utilities";

@import "intl-tel-input/build/css/intlTelInput.css";

@import "aos/dist/aos.css";

@import "variables";
@import "layout";
@import "fonts";
@import "icons";
@import "typo";
@import "tooltip";
@import "phone";
@import "dialog";

html, body { height: 100%; }
