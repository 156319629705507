:root,
.reset-colors {
  // Colors
  --color-snoofa: #ff0000;
  --color-primary: #2a2a2a;
  --color-secondary: #edda31;
  --color-complementary: #ffffff;
  --color-green: #189950;
  --color-green-dark: #0f773d;
  --color-orange: #fc8e24;
  --color-red: #ff0000;
  --color-silver: #f5f5f5;
  --color-silver-dark: #d8d8d8;
  --color-gray: #b3b3b3;
  --color-black: #808080;
  --color-black-dark: #111111;
  --color-black-transparent: rgba(0, 0, 0, 0.6);

  // Shadows
  --box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.08);
  --box-shadow-down: -2px -2px 6px rgba(0, 0, 0, 0.08);
  --box-shadow-header: 0px 0px 5px rgba(0, 0, 0, 0.1);
  --box-shadow-light: 1px 1px 1px rgba(0, 0, 0, 0.08);

  // Transitions
  --transition: 0.2s all ease-in-out;
  --transition-slow: 0.8s all ease-in-out;
}

.snoofa-black {
  --color-snoofa: #000000;
}

.snoofa-red {
  --color-snoofa: #ff0000;
}

.snoofa-violet {
  --color-snoofa: #f0267a;
}

.snoofa-green {
  --color-snoofa: #a2d411;
}

.snoofa-purple {
  --color-snoofa: #6f2c7a;
}

.snoofa-orange {
  --color-snoofa: #fc8e24;
}

.violet-red {
  --color-primary: #ff72de;
  --color-secondary: #92003d;
  --color-complementary: #f0267a;
}

.fun-green {
  --color-primary: #193c28;
  --color-secondary: #b7eb55;
}

.pink-yellow {
  --color-primary: #f0267a;
  --color-secondary: #fff06b;
}

.biscay {
  --color-primary: #174463;
  --color-secondary: #b7eeb5;
  --color-complementary: #a4dace;
}

// New

.napoleon {
  --color-primary: #174463;
  --color-secondary: #a4dace;
  --color-complementary: #a4dace;
  --color-text: #ffffff;
}

.egyptian {
  --color-primary: #a4dace;
  --color-secondary: #174463;
  --color-complementary: #174463;
  --color-text: #ffffff;
}

.emerald {
  --color-primary: #189950;
  --color-secondary: #b7eb55;
  --color-complementary: #b7eb55;
  --color-text: #ffffff;
}

.pompadour {
  --color-primary: #f02671;
  --color-secondary: #fff06b;
  --color-complementary: #fff06b;
  --color-text: #ffffff;
}

.orpamint {
  --color-primary: #fc8e24;
  --color-secondary: #603205;
  --color-complementary: #603205;
  --color-text: #ffffff;
}

.prussian {
  --color-primary: #057188;
  --color-secondary: #ffffff;
  --color-complementary: #ffffff;
  --color-text: #ffffff;
}

.cadet {
  --color-primary: #89e6f1;
  --color-secondary: #057188;
  --color-complementary: #057188;
  --color-text: #000000;
}

.chartreuse {
  --color-primary: #b7eb55;
  --color-secondary: #189950;
  --color-complementary: #189950;
  --color-text: #000000;
}

.neon-quartz {
  --color-primary: #ff72de;
  --color-secondary: #fff06b;
  --color-complementary: #fff06b;
  --color-text: #000000;
}

.genepi {
  --color-primary: #fff06b;
  --color-secondary: #dc7bb0;
  --color-complementary: #f02671;
  --color-text: #000000;
}

.relic {
  --color-primary: #999999;
  --color-secondary: #ffffff;
  --color-complementary: #ffffff;
  --color-text: #000000;
}
