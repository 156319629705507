@font-face {
  font-family: "satoshi";
  src: url("../assets/fonts/satoshi/Satoshi-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "satoshi";
  src: url("../assets/fonts/satoshi/Satoshi-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
