@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icons/icomoon.eot?udiu2e");
  src: url("../assets/fonts/icons/icomoon.eot?udiu2e#iefix") format("embedded-opentype"),
    url("../assets/fonts/icons/icomoon.ttf?udiu2e") format("truetype"),
    url("../assets/fonts/icons/icomoon.woff?udiu2e") format("woff"),
    url("../assets/fonts/icons/icomoon.svg?udiu2e#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  //noinspection CssNoGenericFontName
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-gavel:before {
  content: "\ea67";
}
.icon-category-sports:before {
  content: "\ea66";
}
.icon-exclamation:before {
  content: "\ea65";
}
.icon-x:before {
  content: "\ea64";
}
.icon-twitter:before {
  content: "\ea64";
}
.icon-circle-eyes:before {
  content: "\ea63";
}
.icon-eyes:before {
  content: "\ea62";
}
.icon-boom-beach-svgrepo-com:before {
  content: "\ea5b";
}
.icon-explosion-svgrepo-com:before {
  content: "\ea5c";
}
.icon-ball-boom-game-svgrepo-com:before {
  content: "\ea5d";
  color: #4f4f4f;
}
.icon-bomb-svgrepo-com:before {
  content: "\ea5e";
}
.icon-cartoon-sad-eyes-svgrepo-com:before {
  content: "\ea5f";
}
.icon-eye-svgrepo-com:before {
  content: "\ea60";
}
.icon-cartoon-eyes-svgrepo-com:before {
  content: "\ea61";
}
.icon-clock1:before {
  content: "\ea57";
}
.icon-dolar:before {
  content: "\ea58";
}
.icon-hand:before {
  content: "\ea59";
}
.icon-star1:before {
  content: "\ea5a";
}
.icon-category-artworks:before {
  content: "\ea45";
}
.icon-category-asian-art:before {
  content: "\ea46";
}
.icon-category-books:before {
  content: "\ea47";
}
.icon-category-clocks:before {
  content: "\ea48";
}
.icon-category-collectables:before {
  content: "\ea49";
}
.icon-category-decorative-art:before {
  content: "\ea4a";
}
.icon-category-fashion:before {
  content: "\ea4b";
}
.icon-category-furniture:before {
  content: "\ea4c";
}
.icon-category-jewellery:before {
  content: "\ea4d";
}
.icon-category-lighting:before {
  content: "\ea4e";
}
.icon-category-militaria:before {
  content: "\ea4f";
}
.icon-category-musical:before {
  content: "\ea50";
}
.icon-category-numismatics:before {
  content: "\ea51";
}
.icon-category-photographs:before {
  content: "\ea52";
}
.icon-category-taxider:before {
  content: "\ea53";
}
.icon-category-vehicles:before {
  content: "\ea54";
}
.icon-category-watches:before {
  content: "\ea55";
}
.icon-category-wines:before {
  content: "\ea56";
}
.icon-envelope:before {
  content: "\ea3d";
}
.icon-filter:before {
  content: "\ea3e";
}
.icon-flash-f:before {
  content: "\ea3f";
}
.icon-flash:before {
  content: "\ea40";
}
.icon-search1:before {
  content: "\ea41";
}
.icon-share:before {
  content: "\ea42";
}
.icon-signal:before {
  content: "\ea43";
}
.icon-times:before {
  content: "\ea44";
}
.icon-forkandknife:before {
  content: "\e90d";
}
.icon-pause:before {
  content: "\f04c";
}
.icon-cogs:before {
  content: "\f085";
}
.icon-gears:before {
  content: "\f085";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-facebook-f:before {
  content: "\f09a";
}
.icon-linkedin:before {
  content: "\f0e1";
}
.icon-youtube:before {
  content: "\f167";
}
.icon-youtube-play:before {
  content: "\f16a";
}
.icon-instagram:before {
  content: "\f16d";
}
.icon-google:before {
  content: "\f1a0";
}
.icon-bed:before {
  content: "\f236";
}
.icon-hotel:before {
  content: "\f236";
}
.icon-Heart---Empty:before {
  content: "\ea36";
}
.icon-Arrow---Right-2:before {
  content: "\ea37";
}
.icon-Heart:before {
  content: "\ea38";
}
.icon-Time-Square:before {
  content: "\ea39";
}
.icon-Profile:before {
  content: "\ea3a";
}
.icon-Lock:before {
  content: "\ea3b";
}
.icon-Home:before {
  content: "\ea3c";
}
.icon-symbol-cites:before {
  content: "\ea32";
}
.icon-symbol-import5:before {
  content: "\ea33";
}
.icon-symbol-import20:before {
  content: "\ea34";
}
.icon-symbol-standardSchema:before {
  content: "\ea35";
}
.icon-broadcast:before {
  content: "\ea31";
}
.icon-Heart-Full:before {
  content: "\ea30";
}
.icon-d:before {
  content: "\e900";
}
.icon-apps:before {
  content: "\e901";
}
.icon-arrow-bottom-left-1:before {
  content: "\e902";
}
.icon-arrow-bottom-left-2:before {
  content: "\e903";
}
.icon-arrow-bottom-left-3-square:before {
  content: "\e904";
}
.icon-arrow-bottom-left-4-square:before {
  content: "\e905";
}
.icon-arrow-bottom-left-5-circle:before {
  content: "\e906";
}
.icon-arrow-bottom-left-6-circle:before {
  content: "\e907";
}
.icon-arrow-bottom-right-1:before {
  content: "\e908";
}
.icon-arrow-bottom-right-2:before {
  content: "\e909";
}
.icon-arrow-bottom-right-3-square:before {
  content: "\e90a";
}
.icon-arrow-bottom-right-4-square:before {
  content: "\e90b";
}
.icon-arrow-bottom-right-5-circle:before {
  content: "\e90c";
}
.icon-arrow-bottom-up-4-square:before {
  content: "\e90e";
}
.icon-arrow-down-1:before {
  content: "\e90f";
}
.icon-arrow-down-2:before {
  content: "\e910";
}
.icon-arrow-down-3-square:before {
  content: "\e911";
}
.icon-arrow-down-4-square:before {
  content: "\e912";
}
.icon-arrow-down-5-circle:before {
  content: "\e913";
}
.icon-arrow-down-6-circle:before {
  content: "\e914";
}
.icon-arrow-left-1:before {
  content: "\e915";
}
.icon-arrow-left-2:before {
  content: "\e916";
}
.icon-arrow-left-3-square:before {
  content: "\e917";
}
.icon-arrow-left-4-square:before {
  content: "\e918";
}
.icon-arrow-left-5-circle:before {
  content: "\e919";
}
.icon-arrow-left-6-circle:before {
  content: "\e91a";
}
.icon-arrow-right-1:before {
  content: "\e91b";
}
.icon-arrow-right-2:before {
  content: "\e91c";
}
.icon-arrow-right-3-square:before {
  content: "\e91d";
}
.icon-arrow-right-4-square:before {
  content: "\e91e";
}
.icon-arrow-right-5-circle:before {
  content: "\e91f";
}
.icon-arrow-right-6-circle:before {
  content: "\e920";
}
.icon-arrow-top-left-1:before {
  content: "\e921";
}
.icon-arrow-top-left-2:before {
  content: "\e922";
}
.icon-arrow-top-left-3-square:before {
  content: "\e923";
}
.icon-arrow-top-left-4-square:before {
  content: "\e924";
}
.icon-arrow-top-left-5-circle:before {
  content: "\e925";
}
.icon-arrow-top-left-6-circle:before {
  content: "\e926";
}
.icon-arrow-top-right-1:before {
  content: "\e927";
}
.icon-arrow-top-right-2:before {
  content: "\e928";
}
.icon-arrow-top-right-3-square:before {
  content: "\e929";
}
.icon-arrow-top-right-5-circle:before {
  content: "\e92a";
}
.icon-arrow-top-right-6-circle:before {
  content: "\e92b";
}
.icon-arrow-up-1:before {
  content: "\e92c";
}
.icon-arrow-up-2:before {
  content: "\e92d";
}
.icon-arrow-up-3-square:before {
  content: "\e92e";
}
.icon-arrow-up-4-square:before {
  content: "\e92f";
}
.icon-arrow-up-5-circle:before {
  content: "\e930";
}
.icon-arrow-up-6-circle:before {
  content: "\e931";
}
.icon-attachment:before {
  content: "\e932";
}
.icon-attention-circle:before {
  content: "\e933";
}
.icon-attention-square:before {
  content: "\e934";
}
.icon-backspace:before {
  content: "\e935";
}
.icon-badge:before {
  content: "\e936";
}
.icon-bluetooth:before {
  content: "\e937";
}
.icon-bookmark-off:before {
  content: "\e938";
}
.icon-bookmark:before {
  content: "\e939";
}
.icon-box:before {
  content: "\e93a";
}
.icon-briefcase:before {
  content: "\e93b";
}
.icon-calculator:before {
  content: "\e93c";
}
.icon-calendar-1:before {
  content: "\e93d";
}
.icon-calendar-2:before {
  content: "\e93e";
}
.icon-calendar-add:before {
  content: "\e93f";
}
.icon-calendar-remove:before {
  content: "\e940";
}
.icon-camera-image:before {
  content: "\e941";
}
.icon-camera-video:before {
  content: "\e942";
}
.icon-category:before {
  content: "\e943";
}
.icon-certificate-badge:before {
  content: "\e944";
}
.icon-check-circle-1:before {
  content: "\e945";
}
.icon-check-circle-2:before {
  content: "\e946";
}
.icon-check-square:before {
  content: "\e947";
}
.icon-check:before {
  content: "\e948";
}
.icon-cheque:before {
  content: "\e949";
}
.icon-clock:before {
  content: "\e94a";
}
.icon-close-circle-1:before {
  content: "\e94b";
}
.icon-close-circle-2:before {
  content: "\e94c";
}
.icon-close-square:before {
  content: "\e94d";
}
.icon-close:before {
  content: "\e94e";
}
.icon-cloud-add:before {
  content: "\e94f";
}
.icon-cloud-clock:before {
  content: "\e950";
}
.icon-cloud-download:before {
  content: "\e951";
}
.icon-cloud-error:before {
  content: "\e952";
}
.icon-cloud-no:before {
  content: "\e953";
}
.icon-cloud-off:before {
  content: "\e954";
}
.icon-cloud-remove:before {
  content: "\e955";
}
.icon-cloud-upload:before {
  content: "\e956";
}
.icon-cloud-yes:before {
  content: "\e957";
}
.icon-cloud:before {
  content: "\e958";
}
.icon-comment-add:before {
  content: "\e959";
}
.icon-comment-close:before {
  content: "\e95a";
}
.icon-comment-dots:before {
  content: "\e95b";
}
.icon-comment-check:before {
  content: "\e95c";
}
.icon-comment-remove:before {
  content: "\e95d";
}
.icon-comment:before {
  content: "\e95e";
}
.icon-compare:before {
  content: "\e95f";
}
.icon-component:before {
  content: "\e960";
}
.icon-confused-face:before {
  content: "\e961";
}
.icon-copy:before {
  content: "\e962";
}
.icon-credit-card:before {
  content: "\e963";
}
.icon-cursor:before {
  content: "\e964";
}
.icon-delivery-fast:before {
  content: "\e965";
}
.icon-delivery-free:before {
  content: "\e966";
}
.icon-delivery:before {
  content: "\e967";
}
.icon-disappointed-face:before {
  content: "\e968";
}
.icon-discount:before {
  content: "\e969";
}
.icon-discover:before {
  content: "\e96a";
}
.icon-dislike:before {
  content: "\e96b";
}
.icon-do-redo:before {
  content: "\e96c";
}
.icon-do-undo:before {
  content: "\e96d";
}
.icon-download:before {
  content: "\e96e";
}
.icon-edit:before {
  content: "\e96f";
}
.icon-email:before {
  content: "\e970";
}
.icon-enter:before {
  content: "\e971";
}
.icon-exit:before {
  content: "\e972";
}
.icon-expressionless-face:before {
  content: "\e973";
}
.icon-eye-off:before {
  content: "\e974";
}
.icon-eye:before {
  content: "\e975";
}
.icon-face-with-open-mouth:before {
  content: "\e976";
}
.icon-face-without-mouth:before {
  content: "\e977";
}
.icon-file-add:before {
  content: "\e978";
}
.icon-file-audio:before {
  content: "\e979";
}
.icon-file-close:before {
  content: "\e97a";
}
.icon-file-document:before {
  content: "\e97b";
}
.icon-file-check:before {
  content: "\e97c";
}
.icon-file-image:before {
  content: "\e97d";
}
.icon-file-remove:before {
  content: "\e97e";
}
.icon-file-video:before {
  content: "\e97f";
}
.icon-file:before {
  content: "\e980";
}
.icon-flag:before {
  content: "\e981";
}
.icon-folder-add:before {
  content: "\e982";
}
.icon-folder-close:before {
  content: "\e983";
}
.icon-folder-document:before {
  content: "\e984";
}
.icon-folder-check:before {
  content: "\e985";
}
.icon-folder-image:before {
  content: "\e986";
}
.icon-folder-music:before {
  content: "\e987";
}
.icon-folder-remove:before {
  content: "\e988";
}
.icon-folder-video:before {
  content: "\e989";
}
.icon-folder:before {
  content: "\e98a";
}
.icon-frame:before {
  content: "\e98b";
}
.icon-frowning-face:before {
  content: "\e98c";
}
.icon-funnel:before {
  content: "\e98d";
}
.icon-gift:before {
  content: "\e98e";
}
.icon-headphone:before {
  content: "\e98f";
}
.icon-heart-off:before {
  content: "\e990";
}
.icon-heart:before {
  content: "\e991";
}
.icon-history:before {
  content: "\e992";
}
.icon-home:before {
  content: "\e993";
}
.icon-information-circle:before {
  content: "\e994";
}
.icon-information-square:before {
  content: "\e995";
}
.icon-invoice:before {
  content: "\e996";
}
.icon-kissing-face-with-smiling-eyes:before {
  content: "\e997";
}
.icon-kissing-face:before {
  content: "\e998";
}
.icon-lightning-1:before {
  content: "\e999";
}
.icon-lightning-2:before {
  content: "\e99a";
}
.icon-like:before {
  content: "\e99b";
}
.icon-link-external:before {
  content: "\e99c";
}
.icon-link:before {
  content: "\e99d";
}
.icon-location-pin-off:before {
  content: "\e99e";
}
.icon-location-pin:before {
  content: "\e99f";
}
.icon-location:before {
  content: "\e9a0";
}
.icon-lock-off:before {
  content: "\e9a1";
}
.icon-lock:before {
  content: "\e9a2";
}
.icon-menu-burger-horizontal:before {
  content: "\e9a3";
}
.icon-menu-burger-vertical:before {
  content: "\e9a4";
}
.icon-menu-kebab-horizontal-circle:before {
  content: "\e9a5";
}
.icon-menu-kebab-horizontal-square:before {
  content: "\e9a6";
}
.icon-menu-kebab-horizontal:before {
  content: "\e9a7";
}
.icon-menu-kebab-vertical-circle:before {
  content: "\e9a8";
}
.icon-menu-kebab-vertical-square:before {
  content: "\e9a9";
}
.icon-menu-kebab-vertical:before {
  content: "\e9aa";
}
.icon-microphone-off:before {
  content: "\e9ab";
}
.icon-microphone:before {
  content: "\e9ac";
}
.icon-mode-dark:before {
  content: "\e9ad";
}
.icon-mode-light:before {
  content: "\e9ae";
}
.icon-mouse:before {
  content: "\e9af";
}
.icon-move:before {
  content: "\e9b0";
}
.icon-music-1:before {
  content: "\e9b1";
}
.icon-music-2:before {
  content: "\e9b2";
}
.icon-music-album:before {
  content: "\e9b3";
}
.icon-music-artist:before {
  content: "\e9b4";
}
.icon-neutral-face:before {
  content: "\e9b5";
}
.icon-news:before {
  content: "\e9b6";
}
.icon-notification-off:before {
  content: "\e9b7";
}
.icon-notification:before {
  content: "\e9b8";
}
.icon-number-0-circle:before {
  content: "\e9b9";
}
.icon-number-0-square:before {
  content: "\e9ba";
}
.icon-number-0:before {
  content: "\e9bb";
}
.icon-number-1-circle:before {
  content: "\e9bc";
}
.icon-number-1-square:before {
  content: "\e9bd";
}
.icon-number-1:before {
  content: "\e9be";
}
.icon-number-2-circle:before {
  content: "\e9bf";
}
.icon-number-2-square:before {
  content: "\e9c0";
}
.icon-number-2:before {
  content: "\e9c1";
}
.icon-number-3-circle:before {
  content: "\e9c2";
}
.icon-number-3-square:before {
  content: "\e9c3";
}
.icon-number-3:before {
  content: "\e9c4";
}
.icon-number-4-circle:before {
  content: "\e9c5";
}
.icon-number-4-square:before {
  content: "\e9c6";
}
.icon-number-4:before {
  content: "\e9c7";
}
.icon-number-5-circle:before {
  content: "\e9c8";
}
.icon-number-5-square:before {
  content: "\e9c9";
}
.icon-number-5:before {
  content: "\e9ca";
}
.icon-number-6-circle:before {
  content: "\e9cb";
}
.icon-number-6-square:before {
  content: "\e9cc";
}
.icon-number-6:before {
  content: "\e9cd";
}
.icon-number-7-circle:before {
  content: "\e9ce";
}
.icon-number-7-square:before {
  content: "\e9cf";
}
.icon-number-7:before {
  content: "\e9d0";
}
.icon-number-8-circle:before {
  content: "\e9d1";
}
.icon-number-8-square:before {
  content: "\e9d2";
}
.icon-number-8:before {
  content: "\e9d3";
}
.icon-number-9-circle:before {
  content: "\e9d4";
}
.icon-number-9-square:before {
  content: "\e9d5";
}
.icon-number-9:before {
  content: "\e9d6";
}
.icon-options:before {
  content: "\e9d7";
}
.icon-pen:before {
  content: "\e9d8";
}
.icon-pensive-face:before {
  content: "\e9d9";
}
.icon-phone-off:before {
  content: "\e9da";
}
.icon-phone:before {
  content: "\e9db";
}
.icon-play-circle:before {
  content: "\e9dc";
}
.icon-player-end:before {
  content: "\e9dd";
}
.icon-player-next:before {
  content: "\e9de";
}
.icon-player-pause:before {
  content: "\e9df";
}
.icon-player-play:before {
  content: "\e9e0";
}
.icon-player-previous:before {
  content: "\e9e1";
}
.icon-player-start:before {
  content: "\e9e2";
}
.icon-player-stop:before {
  content: "\e9e3";
}
.icon-playlist-repeat-list:before {
  content: "\e9e4";
}
.icon-playlist-repeat-song:before {
  content: "\e9e5";
}
.icon-playlist-shuffle:before {
  content: "\e9e6";
}
.icon-playlist:before {
  content: "\e9e7";
}
.icon-printer:before {
  content: "\e9e8";
}
.icon-profile-circle:before {
  content: "\e9e9";
}
.icon-profile:before {
  content: "\e9ea";
}
.icon-question-mark-circle:before {
  content: "\e9eb";
}
.icon-question-mark-square:before {
  content: "\e9ec";
}
.icon-relieved-face:before {
  content: "\e9ed";
}
.icon-restart:before {
  content: "\e9ee";
}
.icon-scanner:before {
  content: "\e9ef";
}
.icon-screen-full:before {
  content: "\e9f0";
}
.icon-screen-normal:before {
  content: "\e9f1";
}
.icon-search:before {
  content: "\e9f2";
}
.icon-send:before {
  content: "\e9f3";
}
.icon-settings:before {
  content: "\e9f4";
}
.icon-share-1:before {
  content: "\e9f5";
}
.icon-share-2:before {
  content: "\e9f6";
}
.icon-shield-no:before {
  content: "\e9f7";
}
.icon-shield-off:before {
  content: "\e9f8";
}
.icon-shield-yes:before {
  content: "\e9f9";
}
.icon-shield:before {
  content: "\e9fa";
}
.icon-shopping-bag:before {
  content: "\e9fb";
}
.icon-shopping-card-add:before {
  content: "\e9fc";
}
.icon-shopping-card-remove:before {
  content: "\e9fd";
}
.icon-shopping-card:before {
  content: "\e9fe";
}
.icon-sign-division-circle:before {
  content: "\e9ff";
}
.icon-sign-division-slash:before {
  content: "\ea00";
}
.icon-sign-division-square:before {
  content: "\ea01";
}
.icon-sign-division:before {
  content: "\ea02";
}
.icon-sign-equal:before {
  content: "\ea03";
}
.icon-sign-f:before {
  content: "\ea04";
}
.icon-sign-factorial:before {
  content: "\ea05";
}
.icon-sign-lemniscate:before {
  content: "\ea06";
}
.icon-sign-minus-circle:before {
  content: "\ea07";
}
.icon-sign-minus-square:before {
  content: "\ea08";
}
.icon-sign-minus:before {
  content: "\ea09";
}
.icon-sign-percent:before {
  content: "\ea0a";
}
.icon-sign-pi:before {
  content: "\ea0b";
}
.icon-sign-plus-circle:before {
  content: "\ea0c";
}
.icon-sign-plus-minus:before {
  content: "\ea0d";
}
.icon-sign-plus-square:before {
  content: "\ea0e";
}
.icon-sign-plus:before {
  content: "\ea0f";
}
.icon-sign-radical:before {
  content: "\ea10";
}
.icon-sign-times-circle:before {
  content: "\ea11";
}
.icon-sign-times-square:before {
  content: "\ea12";
}
.icon-sign-times:before {
  content: "\ea13";
}
.icon-sign-x:before {
  content: "\ea14";
}
.icon-sign-y:before {
  content: "\ea15";
}
.icon-slightly-smiling-face:before {
  content: "\ea16";
}
.icon-smiling-face:before {
  content: "\ea17";
}
.icon-sorting-center:before {
  content: "\ea18";
}
.icon-sorting-left:before {
  content: "\ea19";
}
.icon-sorting-right:before {
  content: "\ea1a";
}
.icon-squinting-face:before {
  content: "\ea1b";
}
.icon-star-off:before {
  content: "\ea1c";
}
.icon-star:before {
  content: "\ea1d";
}
.icon-store:before {
  content: "\ea1e";
}
.icon-swap:before {
  content: "\ea1f";
}
.icon-synchronize:before {
  content: "\ea20";
}
.icon-ticket:before {
  content: "\ea21";
}
.icon-trash-simple:before {
  content: "\ea22";
}
.icon-trash:before {
  content: "\ea23";
}
.icon-trend-down:before {
  content: "\ea24";
}
.icon-trend-up:before {
  content: "\ea25";
}
.icon-type:before {
  content: "\ea26";
}
.icon-unavailable:before {
  content: "\ea27";
}
.icon-upload:before {
  content: "\ea28";
}
.icon-upside-down-face:before {
  content: "\ea29";
}
.icon-volume-down:before {
  content: "\ea2a";
}
.icon-volume-off:before {
  content: "\ea2b";
}
.icon-volume-up:before {
  content: "\ea2c";
}
.icon-winking-face:before {
  content: "\ea2d";
}
.icon-zoom-in:before {
  content: "\ea2e";
}
.icon-zoom-out:before {
  content: "\ea2f";
}
