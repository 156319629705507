.tooltip {
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 11px;
  line-height: 14px;
  z-index: 100;
  max-width: 200px;
  width: auto;
  white-space: normal;
  -webkit-font-smoothing: initial;

  &.top {
    top: 100%;
    right: 0;
    margin-top: 5px;
  }

  &.bottom {
    bottom: 100%;
    left: 0;
    margin-bottom: 5px;
  }

  &.custom-card-auction-type {
    bottom: 100%;
    right: -5px;
    margin-bottom: 10px;
  }

  &.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.min-width {
    min-width: 100px;
  }
}
