.phone-input {
  border: 1px solid var(--color-silver-dark);
  background: #fff;
  border-radius: 20px;
  text-align: left;
  height: 44px;
  line-height: 44px;
  width: 100%;

  &::placeholder {
    color: var(--color-gray);
    font-size: 12px;
    font-weight: bold;
    text-align: left;
  }
}

.iti {
  display: block;

  &__selected-flag {
    border-radius: 20px 0 0 20px;
  }

  input {
    &::placeholder {
      color: var(--color-gray);
      font-size: 12px;
      font-weight: bold;
      text-align: left;
    }
  }
}
